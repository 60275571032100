<template>
  <apexchart :width="Width" :height="Height" type="bar" :options="chartOptions" :series="Series"></apexchart>
</template>
<script>
function chartOptions(){
  let Chart = {
    chart: {
      type: 'bar',
      animations: {
        enabled: true,
      }
    },
    colors: this.Colors.length != 0 ? this.Colors : undefined,
    xaxis: {
      labels: {
        show: this.Xaxis,
        style: {
          fontSize: '10px',
          colors: ['#fffff'],
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      group: {},
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: this.Distributed,
        ...this.PlotOptions,
      }
    },
    legend: this.Legend,
    tooltip: this.Tooltip,
    dataLabels: this.DataLabels,
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff']
    },
    yaxis: this.Yaxis,
    grid: {
      show: false,
    },
  }
  if (this.Categories.length != 0) {
    Chart.xaxis.categories = this.Categories;
  }
  if (this.Groups.length != 0) {
    Chart.xaxis.group.groups = this.Groups;
  }
  if (this.Max != 0) {
    Chart.xaxis.max = this.Max*1.4;
  }
  return Chart;
}


export default {
name:'barVerticalApexChart',
props: {
  Series: {
    type: Array,
    require: true,
    default: () => [],
  },
  Categories: {
    type: Array,
    default: () => [],
  },
  Colors: {
    type: Array,
    require: false,
    default: () => [],
  },
  Tooltip: {
    type: Object ,
    required: false,
    default: function () {
      return {
        y: {
          formatter: function (Value) {
            return Number.parseInt(Value);
          }
        }
      } 
    },
  },
  DataLabels: {
    type: Object ,
    required: false,
    default: () => { 
      return {
        enabled: true 
      }
    },
  },
  Yaxis: {
    type: Object ,
    required: false,
    default: () => { 
      return {
        show: true
      }
    },
  },
  PlotOptions: {
    type: Object ,
    required: false,
    default: () => {},
  },
  Groups: {
    type: Array ,
    required: false,
    default: () => [],
  },
  Xaxis: {
    type: Boolean,
    default: false,
  },
  Legend: {
    type: Object,
    default: () => {},
  },
  Distributed: {
    type: Boolean,
    default: false,
  },
  Width:{
    type: String,
    require: true,
    default: 'auto',
  },
  Height:{
    type: String,
    require: true,
    default: 'auto',
  },
  Max: {
    type: Number,
    default: () => 0,
  }
},
methods:{

},
computed:{
  chartOptions
},
}
</script>